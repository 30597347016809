import React, { useEffect, useRef } from 'react';
import Layout from '../components/layout';
import CtaDeck from '../components/common/cta-deck';

const headMeta = {
	title: 'Cookie Declaration | Pandev Law',
	description: 'Learn more about Pandev Law&#39;s Cookie Declaration.',
	keywords: '',
};

const schema = `{}`;

function CookieDeclaration({ location }) {
	const titleRef = useRef();

	useEffect(() => {
		if (titleRef.current) {
			const script = document.createElement('script');
			script.id = 'CookieDeclaration';
			script.src =
				'https://consent.cookiebot.com/b1f14905-7044-4e52-8d1f-9a03c8bc6581/cd.js';
			script.type = 'text/javascript';

			titleRef.current.parentNode.insertBefore(script, titleRef.current);
		}
	});

	return (
		<Layout headMeta={headMeta} location={location} footerSchema={schema}>
			<div className="section pt-5 pb-5 pt-md-10 pb-md-10">
				<div className="container">
					<div className="row">
						<div className="offset-lg-2 col-lg-8">
							<h1 className="text-mega mb-8">Cookie Declaration</h1>
							<div ref={titleRef} />
						</div>
					</div>
				</div>
			</div>
			<CtaDeck />
		</Layout>
	);
}

export default CookieDeclaration;
